import R from 'app/assets/R'
import {
  SvgAttendanceRecordFocusIcon,
  SvgAttendanceRecordIcon,
  SvgCustomerCareFocusIcon,
  SvgCustomerCareIcon,
  SvgDashboardFocusIcon,
  SvgDashboardIcon,
  SvgHumanResourcesFocusIcon,
  SvgHumanResourcesIcon,
  SvgInventoryManagementFocusIcon,
  SvgInventoryManagementIcon,
  SvgMailBoxFocusIcon,
  SvgMailBoxIcon,
  SvgWorkScheduleFocusIcon,
  SvgWorkScheduleIcon,
} from 'app/assets/svg-assets'
import { PermissionEnum } from 'app/common/enum'
import {
  ACCOUNT_MANAGEMENT_PATH,
  CHINH_SACH_PHAP_LUAT_HO_TRO_CHUYEN_MON_PATH,
  CONG_TAC_NU_CONG_PATH,
  DASHBOARD_PATH,
  EVENT_MANAGEMENT_PATH,
  LUAT_DIEU_LE_CONG_DOAN_PATH,
  NEW_UEH_PATH,
  REGISTER_ACTIVITY_PATH,
  ROLE_MANAGEMENT_PATH,
  THI_DUA_KHEN_THUONG_PATH,
  TIN_TIEU_DIEM_PATH,
  TRADE_UNION_MEMBER_MANAGEMENT_PATH,
  TUYEN_GIAO_VAN_THE_PATH,
  VAN_BAN_BIEU_MAU_PATH
} from 'app/components/router/route-path'
import React from 'react'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
  iconFocus?: React.ReactNode
  permission?: PermissionEnum
  isLink?: boolean
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: R.strings.dashboard,
    key: R.strings.dashboard,
    url: DASHBOARD_PATH,
    icon: <SvgDashboardIcon />,
    iconFocus: <SvgDashboardFocusIcon />,
    children: undefined,
  },
  {
    title: 'Quản lý Công đoàn viên',
    key: R.strings.trade_union_member,
    icon: <SvgHumanResourcesIcon />,
    iconFocus: <SvgInventoryManagementFocusIcon />,
    children: [
      {
        title: R.strings.account_management,
        key: R.strings.account_management,
        url: ACCOUNT_MANAGEMENT_PATH,
        icon: <SvgHumanResourcesIcon />,
        iconFocus: <SvgHumanResourcesFocusIcon />,
        permission: PermissionEnum.ReadAccount,
      },
      {
        title: R.strings.role_management,
        key: R.strings.role_management,
        url: ROLE_MANAGEMENT_PATH,
        icon: <SvgInventoryManagementIcon />,
        iconFocus: <SvgInventoryManagementFocusIcon />,
        permission: PermissionEnum.ReadRole,
      },
      {
        title: 'Danh sách Công đoàn viên',
        key: R.strings.trade_union_members_management,
        url: TRADE_UNION_MEMBER_MANAGEMENT_PATH,
        icon: <SvgCustomerCareIcon />,
        iconFocus: <SvgCustomerCareFocusIcon />,
        permission: PermissionEnum.ReadTradeUnionMember,
      },
    ],
  },
  {
    title: R.strings.event_management,
    key: R.strings.event_management,
    url: EVENT_MANAGEMENT_PATH,
    icon: <SvgWorkScheduleIcon />,
    iconFocus: <SvgWorkScheduleFocusIcon />,
    permission: PermissionEnum.ReadEvent,
    children: undefined,
  },
  {
    title: R.strings.register_join_activity,
    key: R.strings.register_join_activity,
    url: REGISTER_ACTIVITY_PATH,
    icon: <SvgAttendanceRecordIcon />,
    iconFocus: <SvgAttendanceRecordFocusIcon />,
    children: undefined,
  },
  {
    title: R.strings.news_union,
    key: R.strings.news_union,
    icon: <SvgMailBoxIcon />,
    iconFocus: <SvgMailBoxFocusIcon />,
    children: [
      {
        title: 'Website Công đoàn UEH',
        key: 'Website Công đoàn UEH',
        url: NEW_UEH_PATH,
        isLink: true,
      },
      {
        title: 'Tin tiêu điểm',
        key: 'Tin tiêu điểm',
        url: TIN_TIEU_DIEM_PATH,
        isLink: true,
      },
      {
        title: 'Công tác nữ công',
        key: 'Công tác nữ công',
        url: CONG_TAC_NU_CONG_PATH,
        isLink: true,
      },
      {
        title: 'Tuyển giao vận thể',
        key: 'Tuyển giao vận thể',
        url: TUYEN_GIAO_VAN_THE_PATH,
        isLink: true,
      },
      {
        title: 'Luật điều lệ công đoàn',
        key: 'Luật điều lệ công đoàn',
        url: LUAT_DIEU_LE_CONG_DOAN_PATH,
        isLink: true,
      },
      {
        title: 'Thi đua khen thưởng',
        key: 'Thi đua khen thưởng',
        url: THI_DUA_KHEN_THUONG_PATH,
        isLink: true,
      },
      {
        title: 'CS-PL-HT chuyên môn',
        key: 'CS-PL-HT chuyên môn',
        url: CHINH_SACH_PHAP_LUAT_HO_TRO_CHUYEN_MON_PATH,
        isLink: true,
      },
      {
        title: 'Văn bản biểu mẫu',
        key: 'Văn bản biểu mẫu',
        url: VAN_BAN_BIEU_MAU_PATH,
        isLink: true,
      },
    ],
  },
  // {
  //   title: R.strings.report,
  //   key: R.strings.report,
  //   url: REPORT_PATH,
  //   icon: <SvgReportIcon />,
  //   iconFocus: <SvgReportFocusIcon />,
  //   permission: PermissionEnum.ReadReport
  // },
]
