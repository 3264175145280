import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { UpdateModal } from './components/UpdateModal'
import {
  InfoLayout,
  ListActionEventLayout,
  OtherRewardLayout,
  RewardOfEventLayout,
} from './layouts'
import * as S from './styles'

export const FormTradeUnionMemberDetails: React.FC = () => {
  const { state } = useLocation()

  const tum: ITradeUnionMember = useMemo(() => {
    return state?.tum
  }, [state])

  return (
    <S.FormContent>
      <HeaderPage
        titleI18nKey={R.strings.union_members_detail}
        rightComponent={<UpdateModal />}
      />
      <BaseSpace>
        <InfoLayout tum={tum} />
        <RewardOfEventLayout tum={tum} />
        <OtherRewardLayout tum={tum} />
        <ListActionEventLayout tum={tum} />
      </BaseSpace>
    </S.FormContent>
  )
}
