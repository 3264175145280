import { CopyOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { notificationController } from 'app/controllers/notification-controller'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export const ItemInfo = ({
  labelKeyI18n,
  value,
  note,
  isHaveCopy,
}: {
  labelKeyI18n?: string
  value?: string
  note?: string
  isHaveCopy?: boolean
}) => {
  const { t } = useTranslation()
  const handleCopy = () => {
    notificationController.success({
      message: t(R.strings.copy_success),
    })
  }
  return (
    <BaseSpace size={8}>
      <BaseRow>
        <BaseCol>
          <BaseText
            children={labelKeyI18n ? t(labelKeyI18n) : ''}
            colorText="primaryGradientButtonColor"
            fontWeight="medium"
            opacity="0.7"
          />
        </BaseCol>
        {note ? (
          <BaseCol>
            <BaseText
              children={`(${note})`}
              fontSize="xxxs"
              opacity="0.5"
              fontWeight="medium"
            />
          </BaseCol>
        ) : null}
      </BaseRow>
      <BaseRow gutter={10} align={'middle'}>
        <BaseCol>
          <BaseText children={value} fontWeight="semibold" />
        </BaseCol>
        {isHaveCopy ? (
          <BaseCol>
            <CopyToClipboard text={value}>
              <CopyOutlinedIcon rev={undefined} onClick={handleCopy} />
            </CopyToClipboard>
          </BaseCol>
        ) : null}
      </BaseRow>
    </BaseSpace>
  )
}

const CopyOutlinedIcon = styled(CopyOutlined)`
  cursor: pointer;
`
