/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestChangeStatusMemberRegister,
  requestCheckInAction,
  requestCheckInActionWithLocation,
  requestCreateActionByEvent,
  requestCreateActionByEventTypeActivity,
  requestCreateEvent,
  requestDeleteEvent,
  requestGetActionByEvent,
  requestGetActionNotJoinByMemberId,
  requestGetActionRegisteredByMember,
  requestGetEventList,
  requestGetEventListWithTum,
  requestGetMemberOfPrize,
  requestGetMemberRegisterByActionId,
  requestGetTeamOfPrize,
  requestPushRewardTum,
  requestRegisterActionByMember,
  requestUpdateActionByEvent,
  requestUpdateActionByEventTypeActivity,
  requestUpdateEvent,
} from 'app/api/event'
import {
  IActionEvent,
  IDeleteEvent,
  IEvent,
  IMemberActionEvent,
  IParamsGetActionByEvent,
  IParamsGetActionRegisteredByMember,
  IParamsGetEvents,
  IParamsGetMemberByAction,
  IPayloadChangeStatusMemberRegister,
  IPayloadCheckInActionByMember,
  IPayloadCreateActionEvent,
  IPayloadCreateEvent,
  IPayloadPushRewardTum,
  IPayloadRegisterActionByMember,
  IPayloadUpdateEvent,
  IResponseEventList,
} from 'app/api/event/model'
import { notificationController } from 'app/controllers/notification-controller'
import { useAppSelector } from 'app/redux/hooks'
import { getToken } from 'app/service/storage/storage-service'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { IResponseQueryList } from '../model/common'
import { EventKeys } from '../query-key/event'
import { ResponseType } from 'parkway-web-common'
import { ITeam } from 'app/containers/EventManagement/layouts/CreateEvent/type'

export function useGetEventList(params: IParamsGetEvents, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IEvent[]>, undefined>(
    EventKeys.listEvent(params),
    async () => {
      try {
        const res: IResponseEventList = await requestGetEventList(params)
        const data: IEvent[] = res?.data?.rows || []
        return {
          data,
          total: res?.data?.count ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetEventWithTum(params: IParamsGetEvents, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IEvent[]>, undefined>(
    EventKeys.listEventWithTum(params),
    async () => {
      try {
        const res: IResponseEventList = await requestGetEventListWithTum(params)
        const data: IEvent[] = res?.data?.rows || []
        return {
          data,
          total: res?.data?.count ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetActionByEvent(params: IParamsGetActionByEvent) {
  return useInfiniteQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listActionEvent(params),
    async () => {
      if (!params.id) {
        return {
          data: [],
          total: 0,
        }
      }

      const data: ResponseType<{
        rows: IActionEvent[]
        count: number
      }> = await requestGetActionByEvent(params)
      return {
        data: data?.data?.rows,
        total: data?.data.count ?? 0,
      }
    },
  )
}

export const useCheckInActionByMember = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCheckInActionByMember, unknown>(
    async (body: IPayloadCheckInActionByMember) => {
      await requestCheckInAction(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useCheckInActionByMemberWithLocation = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCheckInActionByMember, unknown>(
    async (body: IPayloadCheckInActionByMember) => {
      await requestCheckInActionWithLocation(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export function useGetActionNotJoinByEvent(params: IParamsGetActionByEvent) {
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listActionEvent(params),
    async () => {
      if (!params.id) {
        return {
          data: [],
          total: 0,
        }
      }

      const data: IActionEvent[] = await requestGetActionByEvent(params)
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export function useGetMemberOfPrize(params: IParamsGetActionByEvent) {
  return useInfiniteQuery<IMemberActionEvent[], undefined>(
    EventKeys.listListMemberOfRewards(params),
    async () => {
      try {
        if (!params.id) {
          return []
        }

        const data: IMemberActionEvent[] = await requestGetMemberOfPrize(params)
        return data
      } catch (error) {
        return []
      }
    },
  )
}

export function useGetTeamOfPrize(params: IParamsGetActionByEvent) {
  return useInfiniteQuery<ITeam[], undefined>(
    EventKeys.listListTeamOfRewards(params),
    async () => {
      try {
        if (!params.id) {
          return []
        }

        const data: IMemberActionEvent[] = await requestGetTeamOfPrize(params)
        return data
      } catch (error) {
        return []
      }
    },
  )
}

export const usePushRewardTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadPushRewardTum, unknown>(
    async (body: IPayloadPushRewardTum) => {
      await requestPushRewardTum(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allListMemberOfReward,
        })
      },
    },
  )
}

export const useDeleteEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IDeleteEvent, unknown>(
    async (body: IDeleteEvent) => {
      await requestDeleteEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
      },
    },
  )
}

export const useUpdateEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateEvent, unknown>(
    async (body: IPayloadUpdateEvent) => {
      await requestUpdateEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useCreateEvent = () => {
  const query = useQueryClient()
  return useMutation<IEvent | undefined, unknown, IPayloadCreateEvent, unknown>(
    async (body?: IPayloadCreateEvent) => {
      const res: ResponseType<IEvent> = await requestCreateEvent(body)
      return res?.data
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useCreateActionEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateActionEvent, unknown>(
    async (body?: IPayloadCreateActionEvent) => {
      return await requestCreateActionByEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useUpdateActionEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateActionEvent, unknown>(
    async (body?: IPayloadCreateActionEvent) => {
      return await requestUpdateActionByEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useCreateActionEventTypeActivity = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateActionEvent, unknown>(
    async (body?: IPayloadCreateActionEvent) => {
      return await requestCreateActionByEventTypeActivity(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useUpdateActionEventTypeActivity = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateActionEvent, unknown>(
    async (body?: IPayloadCreateActionEvent) => {
      return await requestUpdateActionByEventTypeActivity(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export function useGetActionNotJoinByMember() {
  const user = useAppSelector(state => state?.user)?.profile
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listListActionNotJoinByMembers(user?.id),
    async () => {
      if (!user?.member?.id) {
        return {
          data: [],
          total: 0,
        }
      }

      const data: ResponseType<IActionEvent[]> =
        await requestGetActionNotJoinByMemberId(user?.id)
      return {
        data: data?.data,
        total: data?.data?.length ?? 0,
      }
    },
  )
}

export function useGetActionRegisteredByMember(
  params: IParamsGetActionRegisteredByMember,
) {
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listListActionRegisteredByMembers(params),
    async () => {
      const data: IActionEvent[] = await requestGetActionRegisteredByMember(
        params,
      )
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export function useGetMemberRegisteredByAction(
  params: IParamsGetMemberByAction,
) {
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listMemberRegisterByActions(params),
    async () => {
      const data: IActionEvent[] = await requestGetMemberRegisterByActionId(
        params,
      )
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

// requestChangeStatusMemberRegister
export const useChangeStatusMemberRegister = () => {
  const query = useQueryClient()
  return useMutation<
    unknown,
    unknown,
    IPayloadChangeStatusMemberRegister,
    unknown
  >(
    async (body?: IPayloadChangeStatusMemberRegister) => {
      return await requestChangeStatusMemberRegister(body)
    },
    {
      onSuccess: () => {
        notificationController?.success({
          message: 'Thành công',
        })
        query.invalidateQueries({
          queryKey: EventKeys.allMemberRegisterByAction,
        })
      },
    },
  )
}

// requestChangeStatusMemberRegister
export const useRegisterActionByMember = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadRegisterActionByMember, unknown>(
    async (body: IPayloadRegisterActionByMember) => {
      return await requestRegisterActionByMember(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allActionsNotJoinByEvent,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allListActionRegisteredByMember,
        })
      },
    },
  )
}
