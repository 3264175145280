/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  IPayloadActionEvent,
  IPayloadCreateActionEvent,
  IPayloadCreateEvent,
  TypeEventEnum,
} from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import {
  useCreateActionEvent,
  useCreateActionEventTypeActivity,
  useCreateEvent,
} from 'app/react-query/hook/event'
import {
  createContext,
  MutableRefObject,
  useContext,
  useRef,
  useState,
} from 'react'
import { useActivityHook } from './layouts/ActionEvent/hook'
import {
  IActivityEvent,
  IColumnActivity,
  IDataTumActivity,
  IFormCreateEvent,
  ILocation,
  IRewardActivity,
  ITeam,
  TypeColumn,
} from './type'
import { isEqual } from 'lodash'
import { useNavigate } from 'react-router'

interface ICreateEventContext {
  form?: FormInstance<IFormCreateEvent>
  modalRef?: MutableRefObject<IRefModal>

  openModal?: () => void
  closeModal?: () => void
  handleSubmit?: () => void

  onChangeInfoEvent?: (values: IFormCreateEvent) => void
  isLoading?: boolean
  prevSteps?: () => void
  current?: number

  activities?: IActivityEvent[]
  addActivity?: (activity: IActivityEvent) => void
  updateTitleInActiveByIndex?: (index: number, title: string) => void
  updateValueCareInActiveByIndex?: (index: number, title: string) => void
  removeActivityByIndex?: (index: number) => void
  addColumnInActivityByIndex?: (index: number, column: IColumnActivity) => void
  removeColumnInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
  ) => void
  updateColumnNameInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    name: string,
  ) => void
  updateColumnTypeInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    type: TypeColumn,
  ) => void
  updateDataTumInActivityByIndex?: (
    activityIndex: number,
    data: IDataTumActivity[],
  ) => void
  updateActivityByIndex?: (index: number, activity: IActivityEvent) => void

  updateRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
    reward: IRewardActivity,
  ) => void

  deleteRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
  ) => void

  addRewardInActivityByIndex?: (
    activityIndex: number,
    reward: IRewardActivity,
  ) => void

  deleteTeamInActivityByIndex?: (
    activityIndex: number,
    teamIndex: number,
  ) => void

  addTeamInActivityByIndex?: (activityIndex: number, team: ITeam) => void

  deleteLocationInActivityByIndex?: (
    activityIndex: number,
    locationIndex: number,
  ) => void

  addLocationInActivityByIndex?: (
    activityIndex: number,
    location: ILocation,
  ) => void

  addTumToTeamByActivityIndex?: (
    activityIndex: number,
    teamIndex: number,
    tums: IDataTumActivity[],
  ) => void

  isLoadingSubmit?: boolean

  eventType?: TypeEventEnum
  onChangeEventType?: (type: TypeEventEnum) => void
}
export const CreateEventContext = createContext<ICreateEventContext>({})

export const CreateEventProvider = ({ children }) => {
  const navigate = useNavigate()
  const [form] = BaseForm.useForm<IFormCreateEvent>()
  const modalRef = useRef<IRefModal>({})
  const [eventType, setEventType] = useState<TypeEventEnum | undefined>(
    undefined,
  )

  const { mutateAsync: mutateAsyncCreateEvent, isLoading: isLoadingSubmit } =
    useCreateEvent()

  const {
    mutateAsync: mutateAsyncCreateActionEvent,
    isLoading: isLoadingSubmitAction,
  } = useCreateActionEvent()

  const {
    mutateAsync: mutateAsyncCreateActionEventTypeActivity,
    isLoading: isLoadingSubmitActionTypeActivity,
  } = useCreateActionEventTypeActivity()

  const [isLoading, setIsLoading] = useState(false)

  const prevSteps = () => {
    navigate(-1)
  }

  const openModal = () => {
    modalRef.current.open?.()
  }

  const closeModal = () => {
    modalRef.current.hide?.()
  }

  const activityHook = useActivityHook()

  const handleSubmit = async (infoPayload?: IFormCreateEvent) => {
    setIsLoading(true)

    try {
      const payload: IPayloadCreateEvent = {
        name_event: infoPayload?.name,
        time_end: infoPayload?.endDate
          ? infoPayload?.endDate?.toISOString()
          : new Date('9999-12-31').toISOString(),
        time_start: infoPayload?.startDate
          ? infoPayload?.startDate?.toISOString()
          : new Date('2000-01-01').toISOString(),
        description: infoPayload?.description ?? '-',
        time_register_end: infoPayload?.endDateRegister?.toISOString(),
        time_register_start: infoPayload?.startDateRegister?.toISOString(),
        type: eventType,
        link: infoPayload?.link,
        documents: [],
        addresses: [],
        time_create_event: new Date().toISOString(),
      }

      const res = await mutateAsyncCreateEvent?.(payload)

      if (res?.id) {
        if (isEqual(eventType, TypeEventEnum.ACTIVITY)) {
          const payloadCreateAction: IPayloadCreateActionEvent = {
            event_id: res?.id,
            actions:
              activityHook?.activities?.map(item => {
                return {
                  activity: {
                    event_id: res?.id,
                    name: item?.title ?? '',
                  },
                  columns:
                    item?.columns?.map(column => {
                      return {
                        type: column?.type,
                        name: column?.name,
                        keyOfMainData: column?.keyOfMainData,
                      }
                    }) ?? [],
                  teams:
                    item?.teams?.map(team => ({
                      name: team?.name,
                      description: team?.description,
                      tums:
                        team?.tums?.map(member => ({
                          id: member?.id,
                          list_key_map_check_in: item?.locations?.map(
                            lo => lo?.key_map_check_in,
                          ),
                          columns: item?.columns?.map(col => {
                            const key = col?.keyOfMainData ?? col?.name ?? ''
                            const valueExist = member[key]
                            return {
                              name: key,
                              value: valueExist,
                            }
                          }),
                        })) ?? [],
                    })) ?? [],
                  reward:
                    item?.rewards?.map(reward => ({
                      name: reward?.name,
                      type_prize: reward?.type,
                      countPerReward: reward?.countPerReward,
                      description: reward?.description,
                      gift: reward?.gift,
                    })) ?? [],

                  locations: item?.locations?.map(location => ({
                    address: location?.address,
                    key_map_check_in: location?.key_map_check_in,
                    lat: location?.lat,
                    long: location?.long,
                    radius: location?.radius,
                  })),
                } as IPayloadActionEvent
              }) ?? [],
          }

          mutateAsyncCreateActionEventTypeActivity?.(payloadCreateAction)
        } else if (isEqual(eventType, TypeEventEnum.NEWS)) {
          // do something
        } else {
          const payloadCreateAction: IPayloadCreateActionEvent = {
            event_id: res?.id,
            actions:
              activityHook?.activities?.map(item => {
                return {
                  activity: {
                    event_id: res?.id,
                    name: item?.title ?? '',
                    description: '',
                  },
                  columns: [],
                  tums: item?.data?.map(member => ({
                    id: member?.id,
                    columns: [],
                    valueCare: item?.valueCare,
                  })),
                  reward: [],
                } as IPayloadActionEvent
              }) ?? [],
          }

          mutateAsyncCreateActionEvent?.(payloadCreateAction)
        }
      }

      form?.resetFields?.()
      activityHook?.resetAllData()
      closeModal?.()
      prevSteps()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeEventType = (type?: TypeEventEnum) => {
    setEventType(type)

    form?.setFieldsValue({
      link: undefined,
    })

    activityHook?.resetAllData()
  }

  return (
    <CreateEventContext.Provider
      value={{
        form,
        modalRef,
        openModal,
        closeModal,
        handleSubmit,
        isLoading,
        prevSteps,
        isLoadingSubmit:
          isLoadingSubmit ||
          isLoading ||
          isLoadingSubmitAction ||
          isLoadingSubmitActionTypeActivity,
        onChangeEventType,
        eventType,
        ...activityHook,
      }}
    >
      {children}
    </CreateEventContext.Provider>
  )
}

export function useCreateEventContext() {
  const context = useContext(CreateEventContext)

  if (context === undefined) {
    throw new Error('useCreateEvent must be used within a CreateEventProvider')
  }
  return context
}
