import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { notificationController } from 'app/controllers/notification-controller'
import { useRuleHook } from 'app/hook'
import { useUpdateTum } from 'app/react-query/hook/tradeUnionMember'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useRef } from 'react'

export const UpdateTumModal = ({ tum }: { tum: ITradeUnionMember }) => {
  const modalRef = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<ITradeUnionMember>()
  const { getEmailRule, getNumberRule, getPhoneRule, getStringRule } =
    useRuleHook()
  const { mutateAsync, isLoading } = useUpdateTum()

  const handleOpen = () => {
    modalRef.current?.open?.()
  }

  const handleSubmit = async (values: ITradeUnionMember) => {
    if (!tum?.id) {
      return
    }

    const res = await mutateAsync({
      ...values,
      id: tum?.id,
    })

    if (res) {
      modalRef.current?.hide?.()
      form?.resetFields()
      notificationController.success({
        message: 'Cập nhật đoàn viên thành công',
      })
    }
  }

  useEffect(() => {
    form?.setFieldsValue(tum)
  }, [tum])

  return (
    <BaseModalComponent
      titleModal={`Cập nhật đoàn viên "${tum?.fullName}"`}
      handleSubmit={form?.submit}
      buttonOpenModal={
        <BaseButton onClick={handleOpen} type="text">
          {'Cập nhật đoàn viên'}
        </BaseButton>
      }
      isLoadingConfirm={isLoading}
      ref={modalRef}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={0}>
            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Họ và tên',
                      isRequire: true,
                    }),
                  ]}
                  label={'Họ và tên'}
                  name={'fullName'}
                >
                  <BaseInput placeholder="Nhập họ và tên" />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getPhoneRule({
                      field: 'Điện thoại di động',
                      isRequire: true,
                    }),
                  ]}
                  label={'Điện thoại di động'}
                  name={'dienThoaiDiDong'}
                >
                  <BaseInput placeholder="Nhập điện thoại di động" />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getEmailRule({
                      field: 'Email',
                      isRequire: true,
                    }),
                  ]}
                  name={'email'}
                  label={'Email'}
                >
                  <BaseInput
                    placeholder="Nhập email"
                    disabled={
                      !(isEqual(tum?.email, 'cập nhật') && isEmpty(tum?.email))
                    }
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Giới tính',
                      isRequire: true,
                    }),
                  ]}
                  label={'Giới tính'}
                  name={'gioiTinh'}
                >
                  <BaseSelect
                    style={{ width: '100%' }}
                    placeholder="Chọn giới tính"
                    options={[
                      {
                        label: 'Nam',
                        value: 'Nam',
                      },
                      {
                        label: 'Nữ',
                        value: 'Nữ',
                      },
                    ]}
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Ngày sinh',
                    }),
                  ]}
                  label={'Ngày sinh'}
                  name={'ngaySinh'}
                >
                  <BaseInput placeholder="Nhập ngày sinh" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Nguyên quán',
                    }),
                  ]}
                  label={'Nguyên quán'}
                  name={'nguyenQuan'}
                >
                  <BaseInput placeholder="Nhập nguyên quán" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getNumberRule({
                      field: 'CMND',
                      isRequire: true,
                    }),
                    {
                      pattern: new RegExp('^[0-9]{9}$|^[0-9]{12}$'),
                      message: 'CMND phải có 9 hoặc 12 kí tự',
                    },
                  ]}
                  label={'CCCD/CMND'}
                  name={'cmnd'}
                >
                  <BaseInput placeholder="Nhập CMND" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Tên đơn vị',
                    }),
                  ]}
                  label={'Tên đơn vị'}
                  name={'tenDonVi'}
                >
                  <BaseInput placeholder="Nhập tên đơn vị" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Tình trạng làm việc',
                    }),
                  ]}
                  label={'Tình trạng làm việc'}
                  name={'tinhTrangLamViec'}
                >
                  <BaseInput placeholder="Nhập tình trạng làm việc" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Dân tộc',
                    }),
                  ]}
                  label={'Dân tộc'}
                  name={'danToc'}
                >
                  <BaseInput placeholder="Nhập dân tộc" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Tôn giáo',
                    }),
                  ]}
                  label={'Tôn giáo'}
                  name={'tonGiao'}
                >
                  <BaseInput placeholder="Nhập tôn giáo" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Nơi ở hiện nay',
                    }),
                  ]}
                  label={'Nơi ở hiện nay'}
                  name={'noiOHienNay'}
                >
                  <BaseInput placeholder="Nhập nơi ở hiện nay" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item label={'Học hàm'} name={'hocHam'}>
                  <BaseInput placeholder="Nhập học hàm" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Học vị',
                    }),
                  ]}
                  label={'Học vị'}
                  name={'hocVi'}
                >
                  <BaseInput placeholder="Nhập học vị" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Ngày vào UEH',
                    }),
                  ]}
                  label={'Ngày vào UEH'}
                  name={'ngayVaoUEH'}
                >
                  <BaseInput placeholder="Nhập ngày vào UEH" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Ngày vào công đoàn',
                    }),
                  ]}
                  label={'Ngày vào công đoàn'}
                  name={'ngayVaoCongDoan'}
                >
                  <BaseInput placeholder="Nhập ngày vào công đoàn" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getNumberRule({
                      field: 'Số thẻ đoàn viên',
                    }),
                  ]}
                  label={'Số thẻ đoàn viên'}
                  name={'soTheDoanVien'}
                >
                  <BaseInput placeholder="Nhập số thẻ đoàn viên" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Tình trạng sinh hoạt',
                      isRequire: true,
                    }),
                  ]}
                  label={'Tình trạng sinh hoạt'}
                  name={'tinhTrangSinhHoat'}
                >
                  <BaseInput placeholder="Nhập tình trạng sinh hoạt" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Tên phân hiệu',
                    }),
                  ]}
                  label={'Tên phân hiệu'}
                  name={'tenPhanHieu'}
                >
                  <BaseInput placeholder="Nhập tên phân hiệu" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
