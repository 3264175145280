/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  IActionEvent,
  IEvent,
  IPayloadActionEvent,
  IPayloadCreateActionEvent,
  IPayloadUpdateEvent,
  TypeEventEnum,
} from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import {
  useGetActionByEvent,
  useUpdateActionEvent,
  useUpdateActionEventTypeActivity,
  useUpdateEvent,
} from 'app/react-query/hook/event'
import { IResponseQueryList } from 'app/react-query/model/common'
import dayjs from 'dayjs'
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useActivityHook } from './layouts/ActionEvent/hook'
import {
  IActivityEvent,
  IColumnActivity,
  IDataTumActivity,
  IFormUpdateEvent,
  ILocation,
  IRewardActivity,
  ITeam,
  TypeColumn,
} from './type'
import { isEqual } from 'lodash'
import { useNavigate } from 'react-router'

interface IUpdateEventContext {
  form?: FormInstance<IFormUpdateEvent>
  modalRef?: MutableRefObject<IRefModal>

  openModal?: () => void
  closeModal?: () => void
  handleSubmit?: () => void

  onChangeInfoEvent?: (values: IFormUpdateEvent) => void
  isLoading?: boolean
  prevSteps?: () => void
  current?: number

  activities?: IActivityEvent[]
  addActivity?: (activity: IActivityEvent) => void
  updateTitleInActiveByIndex?: (index: number, title: string) => void
  updateValueCareInActiveByIndex?: (index: number, value: string) => void
  removeActivityByIndex?: (index: number) => void
  addColumnInActivityByIndex?: (index: number, column: IColumnActivity) => void
  removeColumnInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
  ) => void
  updateColumnNameInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    name: string,
  ) => void
  updateColumnTypeInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    type: TypeColumn,
  ) => void
  updateDataTumInActivityByIndex?: (
    activityIndex: number,
    data: IDataTumActivity[],
  ) => void
  updateActivityByIndex?: (index: number, activity: IActivityEvent) => void

  updateRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
    reward: IRewardActivity,
  ) => void

  deleteRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
  ) => void

  addRewardInActivityByIndex?: (
    activityIndex: number,
    reward: IRewardActivity,
  ) => void

  isLoadingSubmit?: boolean

  eventType?: TypeEventEnum
  onChangeEventType?: (type: TypeEventEnum) => void
  event?: IEvent

  deleteTeamInActivityByIndex?: (
    activityIndex: number,
    teamIndex: number,
  ) => void

  addTeamInActivityByIndex?: (activityIndex: number, team: ITeam) => void

  deleteLocationInActivityByIndex?: (
    activityIndex: number,
    locationIndex: number,
  ) => void

  addLocationInActivityByIndex?: (
    activityIndex: number,
    location: ILocation,
  ) => void

  addTumToTeamByActivityIndex?: (
    activityIndex: number,
    teamIndex: number,
    tums: IDataTumActivity[],
  ) => void
}
export const UpdateEventContext = createContext<IUpdateEventContext>({})

export const UpdateEventProvider = ({
  children,
  event,
}: {
  children?: ReactNode
  event?: IEvent
}) => {
  const navigation = useNavigate()
  const [form] = BaseForm.useForm<IFormUpdateEvent>()
  const modalRef = useRef<IRefModal>({})
  const [eventType, setEventType] = useState<TypeEventEnum | undefined>(
    undefined,
  )
  const { flattenDataList } = usePagination()

  const { mutateAsync: mutateAsyncUpdateEvent, isLoading: isLoadingSubmit } =
    useUpdateEvent()

  const {
    mutateAsync: mutateAsyncUpdateActionEventTypeActivity,
    isLoading: isLoadingSubmitActionTypeActivity,
  } = useUpdateActionEventTypeActivity()

  const {
    mutateAsync: mutateAsyncUpdateActionEvent,
    isLoading: isLoadingSubmitAction,
  } = useUpdateActionEvent()

  const [isLoading, setIsLoading] = useState(false)
  const [current, setCurrent] = useState(0)

  const { data: dataApi } = useGetActionByEvent({
    id: event?.id ?? 0,
    eventId: event?.id,
  })

  const dataActivity = useMemo(() => {
    const data: IResponseQueryList<IActionEvent[]> = flattenDataList(dataApi)

    const activityEvents: IActivityEvent[] =
      data?.data?.map((item, index) => {
        const columns = item?.columns?.map(column => {
          return {
            name: column?.name_column,
            keyOfMainData: column?.key_of_main_data,
            type: column?.type_column,
          } as IColumnActivity
        })

        const rewards = item?.rewards?.map(reward => {
          return {
            name: reward?.name,
            countPerReward: reward?.countPerReward,
            description: reward?.description,
            gift: reward?.gift,
            type: reward?.type,
          } as IRewardActivity
        })

        const dataR = item?.members?.map(member => {
          let data: IDataTumActivity = {
            ...member.tum,
            id: member?.tum?.id,
            checkedIn: member?.is_checked_in,
          }

          columns?.forEach(column => {
            data = {
              ...data,
              [column?.name ?? '']: member?.listMemberCategory?.find(
                category => category?.idCategory === column?.keyOfMainData,
              )?.value,
            }
          })

          return data
        })

        const locations = item?.locations?.map(location => {
          return {
            id: location?.id,
            address: location?.address,
            lat: location?.lat,
            long: location?.long,
            radius: location?.radius,
            key_map_check_in: location?.key_map_check_in,
          } as ILocation
        })

        const teams = item?.teams?.map(team => {
          const tums = (team?.tums as any[])?.map(member => {
            let data: IDataTumActivity = {
              ...member.tum,
              id: member?.tum?.id,
              checkedIn: member?.is_checked_in,
              valueCare: member?.value_care,
            }

            columns?.forEach(column => {
              data = {
                ...data,
                [column?.name ?? '']: member?.listMemberCategory?.find(
                  category => category?.idCategory === column?.keyOfMainData,
                )?.value,
              }
            })

            return data
          })
          return {
            id: team?.id,
            name: team?.name,
            description: team?.description,
            tums,
          } as ITeam
        })

        return {
          title: item?.name,
          valueCare: item?.valueCare,
          columns,
          data: dataR,
          rewards,
          key: index + 1,
          teams,
          locations,
        }
      }) ?? []

    return activityEvents ?? []
  }, [dataApi])

  const prevSteps = () => {
    navigation(-1)
  }

  const activityHook = useActivityHook()

  const handleSubmit = async (formPayload?: IFormUpdateEvent) => {
    if (!event?.id) {
      return
    }

    setIsLoading(true)
    try {
      const payload: IPayloadUpdateEvent = {
        id: event?.id,
        name_event: formPayload?.name,
        time_end: formPayload?.endDate
          ? formPayload?.endDate?.toISOString()
          : new Date('9999-12-31').toISOString(),
        time_start: formPayload?.startDate
          ? formPayload?.startDate?.toISOString()
          : new Date('2000-01-01').toISOString(),
        description: formPayload?.description ?? '-',
        time_register_end: formPayload?.endDateRegister?.toISOString(),
        time_register_start: formPayload?.startDateRegister?.toISOString(),
        type: eventType,
        link: formPayload?.link,
        documents: [],
        addresses: [],
        time_create_event: new Date().toISOString(),
      }

      await mutateAsyncUpdateEvent?.(payload)

      if (isEqual(eventType, TypeEventEnum.ACTIVITY)) {
        const payloadCreateAction: IPayloadCreateActionEvent = {
          event_id: event?.id,
          actions:
            activityHook?.activities?.map(item => {
              return {
                activity: {
                  event_id: event?.id,
                  name: item?.title ?? '',
                },
                columns:
                  item?.columns?.map(column => {
                    return {
                      type: column?.type,
                      name: column?.name,
                      keyOfMainData: column?.keyOfMainData,
                    }
                  }) ?? [],
                tums:
                  item?.data?.map(member => ({
                    id: member?.id,
                    is_checked_in: member?.checkedIn,
                    columns: item?.columns?.map(col => {
                      const key = col?.keyOfMainData ?? col?.name ?? ''
                      const valueExist = member[key]
                      return {
                        name: key,
                        value: valueExist,
                      }
                    }),
                  })) ?? [],
                teams:
                  item?.teams?.map(team => ({
                    name: team?.name,
                    description: team?.description,
                    tums:
                      team?.tums?.map(member => ({
                        id: member?.id,
                        list_key_map_check_in: item?.locations?.map(
                          lo => lo?.key_map_check_in,
                        ),
                        columns: item?.columns?.map(col => {
                          const key = col?.keyOfMainData ?? col?.name ?? ''
                          const valueExist = member[key]
                          return {
                            name: key,
                            value: valueExist,
                          }
                        }),
                      })) ?? [],
                  })) ?? [],
                reward:
                  item?.rewards?.map(reward => ({
                    name: reward?.name,
                    type_prize: reward?.type,
                    countPerReward: reward?.countPerReward,
                    description: reward?.description,
                    gift: reward?.gift,
                  })) ?? [],

                locations: item?.locations?.map(location => ({
                  address: location?.address,
                  key_map_check_in: location?.key_map_check_in,
                  lat: location?.lat,
                  long: location?.long,
                  radius: location?.radius,
                })),
              } as IPayloadActionEvent
            }) ?? [],
        }

        await mutateAsyncUpdateActionEventTypeActivity?.(payloadCreateAction)
      } else if (isEqual(eventType, TypeEventEnum.NEWS)) {
        // do something
      } else {
        const payloadCreateAction: IPayloadCreateActionEvent = {
          event_id: event?.id,
          actions:
            activityHook?.activities?.map(item => {
              return {
                activity: {
                  event_id: event?.id,
                  name: item?.title ?? '',
                  description: '',
                },
                columns: [],
                tums: item?.data?.map(member => ({
                  id: member?.id,
                  columns: [],
                })),
                reward: [],
              } as IPayloadActionEvent
            }) ?? [],
        }

        await mutateAsyncUpdateActionEvent?.(payloadCreateAction)
      }

      notificationController.success({
        message: 'Cập nhật sự kiện thành công',
      })

      setCurrent(0)
      prevSteps?.()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!event) {
      notificationController?.error?.({
        message: 'Không tìm thấy sự kiện',
      })
      prevSteps?.()
      return
    }

    if (event) {
      form?.setFieldsValue({
        name: event?.name_event,
        description: event?.description,
        startDate: event?.time_start ? dayjs(event?.time_start) : undefined,
        endDate: event?.time_end ? dayjs(event?.time_end) : undefined,
        startDateRegister: event?.time_register_start
          ? dayjs(event?.time_register_start)
          : undefined,
        endDateRegister: event?.time_register_end
          ? dayjs(event?.time_register_end)
          : undefined,

        link: event?.link,
        type: event?.type,
      })

      setEventType(event?.type)
      activityHook?.handleInitActivity?.(dataActivity)
    }
  }, [event, dataActivity])

  const onChangeEventType = (type?: TypeEventEnum) => {
    setEventType(type)

    form?.setFieldsValue({
      link: undefined,
    })
  }

  return (
    <UpdateEventContext.Provider
      value={{
        form,
        modalRef,
        handleSubmit,
        isLoading,
        prevSteps,
        current,
        isLoadingSubmit:
          isLoadingSubmit ||
          isLoadingSubmitAction ||
          isLoadingSubmitActionTypeActivity,
        eventType,
        onChangeEventType,
        event,
        ...activityHook,
      }}
    >
      {children}
    </UpdateEventContext.Provider>
  )
}

export function useUpdateEventContext() {
  const context = useContext(UpdateEventContext)

  if (context === undefined) {
    throw new Error('useUpdateEvent must be used within a UpdateEventProvider')
  }
  return context
}
