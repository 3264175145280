import { DashOutlined } from '@ant-design/icons'
import { IAccount } from 'app/api/account/model'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useDebounce, usePagination } from 'app/hook'
import { useGetAccountList } from 'app/react-query/hook/account'
import { IResponseQueryList } from 'app/react-query/model/common'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  PADDING,
  Pagination,
  initialPagination,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  ChangePasswordModalLayout,
  DeleteModalLayout,
  UpdateModalLayout,
} from './layouts'
import { IFilterAccountList } from './type'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { PermissionEnum } from 'app/common/enum'
import { GenerateWorkInfo } from 'app/components/header/components/profile/ProfileDropdown'
import axios from 'axios'
export const useHook = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilterAccountList>({})

  const { user, verifyPermission } = useVerifyPermission()

  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const filterDebounce = useDebounce(filter, 500)

  const { flattenDataList } = usePagination()
  const { data, isLoading } = useGetAccountList({
    limit: pagination?.pageSize,
    page: pagination?.current,
    roleIds: filterDebounce?.roleIds,

    search: filterDebounce?.search,
    is_active: filterDebounce?.status,
  })

  const flattenData = useMemo(() => {
    const res: IResponseQueryList<IAccount[]> = flattenDataList(data)

    return res
  }, [data])

  const dataTable = useMemo(() => {
    return flattenData?.data
  }, [flattenData])

  const total = useMemo(() => {
    return flattenData?.total ?? dataTable?.length
  }, [flattenData])

  const isHavePermissionUpdateAccount = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateAccount)
  }, [user])

  const isHavePermissionChangePassword = useMemo(() => {
    return verifyPermission(PermissionEnum.ChangePassword)
  }, [user])

  const isHavePermissionDeleteAccount = useMemo(() => {
    return verifyPermission(PermissionEnum.DeleteAccount)
  }, [user])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IAccount>({
        key: 'full_name',
        title: t(R.strings.full_name),
        classNameWidthColumnOverwrite: 'very-big-column',
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseAvatarProfile
              name={record?.full_name}
              jobTitle={GenerateWorkInfo({
                isTradeUnionMember: record?.isTradeUnionMember,
                roles: record?.roles,
              })}
              src={record?.avatar}
            />
          )
        },
      }),
      convertColumnTable<IAccount>({
        key: 'phone',
        title: t(R.strings.phone),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.phone ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<IAccount>({
        key: 'email',
        title: t(R.strings.email),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.email ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<IAccount>({
        key: 'roles',
        title: t(R.strings.role),
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseRow gutter={[8, 8]}>
              {record?.roles?.map((item, index) => {
                return (
                  <BaseCol key={index}>
                    <RoleItem key={index}>
                      <BaseText children={item?.name} />
                    </RoleItem>
                  </BaseCol>
                )
              })}
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IAccount>({
        key: 'is_active',
        title: t(R.strings.status),
        render: (_, record) => {
          return (
            <StatusWrapper $isActive={!!record?.is_active}>
              <BaseText
                children={
                  record?.is_active
                    ? t(R.strings.active_content)
                    : t(R.strings.in_active)
                }
                colorText={
                  record?.is_active ? 'statesGreenColor' : 'statesRedColor'
                }
                fontWeight="medium"
              />
            </StatusWrapper>
          )
        },
      }),

      convertColumnTable<IAccount>({
        key: 'is_active',
        title: '',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BasePopover
              trigger="click"
              content={
                <BaseSpace>
                  {isHavePermissionUpdateAccount ? (
                    <UpdateModalLayout account={record} />
                  ) : null}

                  {isHavePermissionChangePassword ? (
                    <ChangePasswordModalLayout account={record} />
                  ) : null}

                  {isHavePermissionDeleteAccount ? (
                    <DeleteModalLayout
                      id={record?.id}
                      name={record?.full_name}
                    />
                  ) : null}
                </BaseSpace>
              }
            >
              <DashOutlined rev={undefined} rotate={90} />
            </BasePopover>
          )
        },
      }),
    ]
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const onChangeFilter = (newFilter: IFilterAccountList) => {
    setFilter(newFilter)
    setPagination(initialPagination)
  }

  const downloadExcel = async (): Promise<void> => {
    try {
      const response = await axios.get(
        `https://dev.api.ueh.cyberdx.tech/admin/account/download`,
        {
          responseType: 'blob', // Thiết lập kiểu phản hồi là blob
          params: {
            roleIds: filterDebounce?.roleIds,
            search: filterDebounce?.search,
            is_active: filterDebounce?.status,
          },
        },
      )

      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'accounts.xlsx') // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }

  return {
    isLoading,
    columns,
    pagination: {
      ...pagination,
      total,
    },
    dataTable,
    handleTableChange,
    onChangeFilter,
    filter,
    downloadExcel,
  }
}

const StatusWrapper = styled.div<{ $isActive?: boolean }>`
  padding: 3px ${PADDING.xxs};
  border: 1px solid
    ${props =>
      props?.$isActive
        ? convertedVariables?.statesGreenColor
        : convertedVariables.statesRedColor};

  background-color: ${props =>
    props?.$isActive
      ? convertedVariables?.statesGreenLightColor
      : convertedVariables.statesRedLightColor};

  border-radius: ${BORDER_RADIUS};
  width: fit-content;
`

const RoleItem = styled.div`
  background-color: ${convertedVariables?.neutralBlack9Color};
  border-radius: ${PADDING.xl};
  padding: 5px 16px;
`
