import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useMemo, useRef } from 'react'
import { IRefModal } from 'app/components/common/ModalComponent'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { IDataFormUpdate, IRuleFormUpdate } from './type'

export const useHook = () => {
  const { t } = useTranslation()
  const refModal = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IDataFormUpdate>()
  const onSubmit = () => {
    refModal?.current?.hide?.()
  }

  const rules: IRuleFormUpdate = useMemo(() => {
    return {
      startDate: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.date_of_union_entry),
          }),
        },
      ],
      unionCardNumber: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.union_card_number),
          }),
        },
      ],
      status: [],
      statusUnionActivities: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.status_union_activities),
          }),
        },
      ],
      unionPositions: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.union_positions),
          }),
        },
      ],
    }
  }, [])
  return {
    rules,
    form,
    onSubmit,
    refModal,
  }
}
