import { ApiClient } from 'app/service/api-service'
import { TradeUnionMemberEndpoint } from './constant'
import {
  IParamsGetTradeUnionMember,
  IPayloadCreateRewardTum,
  IPayloadCreateTradeUnionMember,
  ITradeUnionMember,
} from './model'

export const requestGetTradeUnionMemberList = (
  params: IParamsGetTradeUnionMember,
) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_TRADE_UNION_MEMBER,
    params: { params },
  })

export const requestGetTumFomUeh = () =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_DATA_FROM_UEH,
    params: {},
  })

export const requestDeleteTradeUnionMember = (listId: string[]) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_DELETE,
    body: { listId },
  })

export const requestUpdateTradeUnionMember = (body?: ITradeUnionMember) =>
  ApiClient.Put({
    url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_UPDATE,
    body,
  })

export const requestSyncTradeUnionMember = (body?: {
  data: ITradeUnionMember[]
}) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_SYNC,
    body,
  })

export const requestCreateTradeUnionMember = (
  body?: IPayloadCreateTradeUnionMember,
) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_CREATE,
    body,
  })

export const requestGetActivityByTumId = (params: { id?: string }) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_ACTION_BY_TUM_ID,
    params: { params },
  })

export const requestGetRewardByTumId = (params: { id?: string }) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_REWARD_BY_TUM_ID,
    params: { params },
  })

export const requestCreateRewardTum = (body?: IPayloadCreateRewardTum) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.CREATE_REWARD_TUM,
    body,
  })

export const requestDeleteRewardTum = (body?: { id: string }) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.DELETE_REWARD_TUM,
    body,
  })

export const requestGetRewardTumByTumId = (params: { id?: string }) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.REWARD_TUM_BY_TUM_ID,
    params: { params },
  })
