/* eslint-disable @typescript-eslint/no-explicit-any */
import { DashOutlined } from '@ant-design/icons'
import { DefineTypeEventEnum } from 'app/api/event/constant'
import { IEvent, TypeEventEnum } from 'app/api/event/model'
import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  EVENT_MANAGEMENT_DETAILS_PATH,
  UPDATE_EVENT_PATH,
} from 'app/components/router/route-path'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { useDebounce, usePagination } from 'app/hook'
import { useGetEventList } from 'app/react-query/hook/event'
import { IResponseQueryList } from 'app/react-query/model/common'
import axios from 'axios'
import { isEqual } from 'lodash'
import { DateUtil, Pagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { DeleteModalLayout } from './layouts'
import { StatusEventWrapper } from './styles'
import {
  DefineEventStatus,
  EventStatusEnum,
  IEventTableData,
  IFilterEventList,
} from './type'

export const useHook = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilterEventList>({})

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const filterDebounce = useDebounce(filter, 500)

  const { data: dataEvent, isLoading } = useGetEventList({
    page,
    limit: pageSize,
    search: filterDebounce?.search,
    status: filterDebounce?.status,
    type: filterDebounce?.type,
  })

  const { flattenDataList } = usePagination()

  const eventData = useMemo(() => {
    const res: IResponseQueryList<IEvent[]> = flattenDataList(dataEvent)
    return res
  }, [dataEvent])

  const { user, verifyPermission } = useVerifyPermission()

  const isHavePermissionUpdateEvent = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateEvent)
  }, [user])

  const isHavePermissionDeleteEvent = useMemo(() => {
    return verifyPermission(PermissionEnum.DeleteEvent)
  }, [user])

  const data: IEventTableData[] = useMemo(() => {
    return eventData?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [eventData])

  const onNavigateDetail = (event: IEvent) => {
    navigate(EVENT_MANAGEMENT_DETAILS_PATH, {
      state: {
        event,
      },
    })
  }

  const onNavigateToUpdate = (event: IEvent) => {
    navigate(UPDATE_EVENT_PATH, {
      state: {
        event,
      },
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IEventTableData>({
        key: 'name_event',
        title: t(R.strings.event_name),
        classNameWidthColumnOverwrite: 'very-big-column',
        fixed: 'left',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.name_event}
              type="Link"
              onClick={() => onNavigateDetail(record)}
            />
          )
        },
      }),
      convertColumnTable<IEventTableData>({
        key: 'description',
        title: 'Thông tin sự kiện',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.description ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<IEventTableData>({
        key: 'time_register_start',
        title: t(R.strings.start_date_register),
        render: (_, record) => {
          if (isEqual(record?.type, TypeEventEnum.NEWS)) {
            return '-'
          }
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.time_register_start
                  ? DateUtil?.formatDate(
                      record?.time_register_start ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
            />
          )
        },
      }),
      convertColumnTable<IEventTableData>({
        key: 'time_register_end',
        title: t(R.strings.end_date_register),
        render: (_, record) => {
          if (isEqual(record?.type, TypeEventEnum.NEWS)) {
            return '-'
          }
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.time_register_end
                  ? DateUtil?.formatDate(
                      record?.time_register_end ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
            />
          )
        },
      }),
      convertColumnTable<IEventTableData>({
        key: 'time_start',
        title: t(R.strings.start_date_event),
        render: (_, record) => {
          if (isEqual(record?.type, TypeEventEnum.NEWS)) {
            return '-'
          }
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.time_start
                  ? DateUtil?.formatDate(
                      record?.time_start ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
            />
          )
        },
      }),

      convertColumnTable<IEventTableData>({
        key: 'time_end',
        title: t(R.strings.end_date_event),
        render: (_, record) => {
          if (isEqual(record?.type, TypeEventEnum.NEWS)) {
            return '-'
          }
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.time_end
                  ? DateUtil?.formatDate(
                      record?.time_end ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
            />
          )
        },
      }),

      convertColumnTable({
        key: 'type',
        title: 'Loại sự kiện',
        render: (type: TypeEventEnum) => {
          return (
            <BaseText
              children={DefineTypeEventEnum[type]?.text ?? '-'}
              fontWeight="semibold"
              colorText="statesGreenColor"
            />
          )
        },
      }),

      convertColumnTable<IEventTableData>({
        key: 'status',
        title: t(R.strings.status),
        fixed: 'right',
        render: (_, record) => {
          if (!record?.status) return null

          return (
            <StatusEventWrapper
              $status={(() => {
                // if (isEqual(record?.type, TypeEventEnum.NEWS)) {
                //   return EventStatusEnum.IN_PROGRESS
                // }
                return record?.status
              })()}
            >
              <BaseText
                children={(() => {
                  // if (isEqual(record?.type, TypeEventEnum.NEWS)) {
                  //   return 'Đang hoạt động'
                  // }
                  return t(DefineEventStatus[record?.status]?.keyI18n)
                })()}
                colorText={(() => {
                  // if (isEqual(record?.type, TypeEventEnum.NEWS)) {
                  //   return 'statesGreenColor'
                  // }
                  switch (record?.status) {
                    case EventStatusEnum.NOT_OPEN:
                      return 'otherBlueColor'
                    case EventStatusEnum.REGISTRATION:
                      return 'otherBlueColor'
                    case EventStatusEnum.REGISTRATION_COMPLETED:
                      return 'statesOrangeColor'
                    case EventStatusEnum.IN_PROGRESS:
                      return 'statesGreenColor'
                    case EventStatusEnum.FINISHED:
                      return 'statesRedColor'
                  }
                })()}
                fontWeight="medium"
              />
            </StatusEventWrapper>
          )
        },
      }),

      convertColumnTable<IEventTableData>({
        key: 'key',
        title: '',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BasePopover
              trigger="click"
              content={
                <BaseSpace>
                  <BaseButton
                    type="text"
                    onClick={() => onNavigateDetail(record)}
                  >
                    <BaseText
                      children={'Xem chi tiết'}
                      fontWeight="medium"
                      fontSize="xs"
                    />
                  </BaseButton>

                  {isEqual(record?.status, EventStatusEnum.NOT_OPEN) ||
                  isEqual(record?.status, EventStatusEnum.REGISTRATION) ||
                  isEqual(
                    record?.status,
                    EventStatusEnum.REGISTRATION_COMPLETED,
                  ) ? (
                    isHavePermissionDeleteEvent ? (
                      <DeleteModalLayout
                        id={record?.id}
                        name={record?.name_event}
                      />
                    ) : null
                  ) : null}

                  {/* {isEqual(record?.status, EventStatusEnum.NOT_OPEN) ||
                  isEqual(record?.status, EventStatusEnum.NOT_OPEN) ||
                  isEqual(record?.status, EventStatusEnum.REGISTRATION) ||
                  isEqual(
                    record?.status,
                    EventStatusEnum.REGISTRATION_COMPLETED,
                  ) ? (
                    isHavePermissionUpdateEvent ? (
                      <BaseButton
                        type="text"
                        onClick={() => onNavigateToUpdate?.(record)}
                      >
                        {'Cập nhật'}
                      </BaseButton>
                    ) : null
                  ) : null} */}

                  {isHavePermissionUpdateEvent ? (
                    <BaseButton
                      type="text"
                      onClick={() => onNavigateToUpdate?.(record)}
                    >
                      {'Cập nhật'}
                    </BaseButton>
                  ) : null}
                </BaseSpace>
              }
            >
              <BaseButton
                icon={<DashOutlined rev={undefined} rotate={90} />}
                type="text"
              />
            </BasePopover>
          )
        },
      }),
    ]
  }, [t])

  const onChangeFilter = (newFilter: IFilterEventList) => {
    setFilter(newFilter)
    setPage(1)
  }

  const handleChangeTable = (pagination?: Pagination) => {
    setPage(pagination?.current ?? 1)
    setPageSize(pagination?.pageSize ?? 10)
  }

  const downloadExcel = async (params: {
    search?: string
    type?: string[]
    status?: string[]
  }): Promise<void> => {
    try {
      const response = await axios.get(
        `https://dev.api.ueh.cyberdx.tech/admin/event/download`,
        {
          responseType: 'blob', // Thiết lập kiểu phản hồi là blob
          params: { ...params },
        },
      )

      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'events.xlsx') // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }

  const handleDownloadEventExcel = async () => {
    console.log('khanh')

    await downloadExcel({
      search: filterDebounce?.search,
      status: filterDebounce?.status,
      type: filterDebounce?.type,
    })
  }

  return {
    isLoading,
    columns,
    data,
    onChangeFilter,
    filter,
    page,
    pageSize,
    total: eventData?.total,
    handleChangeTable,
    handleDownloadEventExcel,
  }
}
