import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IFilterAccountList } from '../../type'

interface IProps {
  onChangeFilter?: (filter: IFilterAccountList) => void
  filter?: IFilterAccountList
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation()

  const onChangeStatus = (value: boolean) => {
    onChangeFilter?.({ ...filter, isActive: value })
  }

  const onChangeSearch = (value: string) => {
    onChangeFilter?.({ ...filter, search: value })
  }

  return (
    <BaseForm>
      <BaseRow gutter={[16, 8]}>
        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.search_by_name_description)}
              value={filter?.search}
              onChange={e => onChangeSearch?.(e?.target?.value)}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              allowClear
              placeholder={t(R.strings.all_status)}
              onChange={value => onChangeStatus?.(value as boolean)}
              value={filter?.isActive}
              options={[
                {
                  label: t(R.strings.active_content),
                  value: 1,
                },
                {
                  label: t(R.strings.in_active),
                  value: 0,
                },
              ]}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  )
}
