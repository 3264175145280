import { IActionEvent, IEvent, StatusMemberRegisterWaiting } from 'app/api/event/model'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { useGetActionRegisteredByMember } from 'app/react-query/hook/event'
import { useAppSelector } from 'app/redux/hooks'
import { isEqual } from 'lodash'
import { DateUtil } from 'parkway-web-common'
import { useMemo } from 'react'

interface IData {
  key?: number
  activity?: IActionEvent
  reason?: string
  event?: IEvent
}

export const ActionRegisteredByStatusLayout = ({
  status,
}: {
  status: StatusMemberRegisterWaiting
}) => {
  const user = useAppSelector(state => state?.user)?.profile
  const { data: response, isLoading } = useGetActionRegisteredByMember({
    member_id: user?.member?.id,
    status,
  })

  const data = useMemo(() => {
    return response?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        index: index + 1,
      }
    })
  }, [response])

  return (
    <BaseSpace>
      <BaseTableManagement
        dataSource={data}
        columns={[
          convertColumnTable<IData>({
            title: 'STT',
            key: 'key',
            classNameWidthColumnOverwrite: 'number-column',
            fixed: 'left',
            render: value => {
              return (
                <BaseText children={value} fontWeight="medium" opacity="0.7" />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Tên hoạt động',
            key: 'activity',
            classNameWidthColumnOverwrite: 'big-column',
            fixed: 'left',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.activity?.name}
                  fontWeight="semibold"
                  colorText={(() => {
                    switch (status) {
                      case StatusMemberRegisterWaiting.WAITING:
                        return 'otherBlueColor'
                      case StatusMemberRegisterWaiting.ACCEPTED:
                        return 'statesGreenColor'
                      case StatusMemberRegisterWaiting.REJECTED:
                        return 'statesRedColor'
                      default:
                        return 'primaryColor'
                    }
                  })()}
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Sự kiện',
            key: 'event',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.activity?.event?.name_event}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Thời gian đăng kí',
            key: 'event',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={`${DateUtil.formatDate(
                    record?.activity?.event?.time_register_start ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )} - ${DateUtil.formatDate(
                    record?.activity?.event?.time_register_start ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )}`}
                  fontWeight="medium"
                  colorText="statesGreenColor"
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Thời gian diễn ra sự kiện',
            key: 'event',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={`${DateUtil.formatDate(
                    record?.activity?.event?.time_start ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )} - ${DateUtil.formatDate(
                    record?.activity?.event?.time_end ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )}`}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          ...(isEqual(status, StatusMemberRegisterWaiting.REJECTED)
            ? [
                convertColumnTable<IData>({
                  title: 'Lý do từ chối',
                  key: 'activity',
                  classNameWidthColumnOverwrite: 'big-column',
                  fixed: 'left',
                  render: (_, record) => {
                    return (
                      <BaseText
                        children={record?.reason}
                        fontWeight="semibold"
                      />
                    )
                  },
                }),
              ]
            : []),
        ]}
        loading={isLoading}
      />
    </BaseSpace>
  )
}
