import { ItemType, MenuItemType } from 'antd/lib/menu/hooks/useItems'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { isEmpty, isEqual } from 'lodash'
import { colors, useResponsive } from 'parkway-web-common'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { SidebarNavigationItem, sidebarNavigation } from '../sidebar-navigation'
import * as S from './styles'
import { REGISTER_ACTIVITY_PATH } from 'app/components/router/route-path'

interface SliderContentProps {
  setCollapsed: (isCollapsed: boolean) => void
  isCollapsed?: boolean
}

const SliderMenu: React.FC<SliderContentProps> = ({
  setCollapsed,
  isCollapsed,
}) => {
  const { user, verifyPermission } = useVerifyPermission()
  const { t } = useTranslation()
  const { mobileOnly } = useResponsive()
  const location = useLocation()

  const listSidebarNavigationWithPermission = useMemo(() => {
    let listNav: SidebarNavigationItem[] = []
    sidebarNavigation?.forEach(item => {
      if (isEqual(item?.url, REGISTER_ACTIVITY_PATH)) {
        if (user?.member?.id) {
          listNav = listNav.concat([item])
        }
      } else {
        if (item?.children) {
          let childNav: SidebarNavigationItem[] = []
          item?.children?.forEach(child => {
            if (!child?.permission) {
              childNav = childNav.concat([child])
            } else {
              if (verifyPermission(child?.permission)) {
                childNav = childNav.concat([child])
              }
            }
          })

          if (childNav?.length) {
            listNav = listNav.concat([
              {
                ...item,
                children: childNav,
              },
            ])
          }
        } else {
          if (!item?.permission) {
            listNav = listNav.concat([item])
          } else if (verifyPermission(item?.permission)) {
            listNav = listNav.concat([item])
          }
        }
      }
    })

    return listNav
  }, [sidebarNavigation, user])

  const sidebarNavFlat = useMemo(() => {
    return listSidebarNavigationWithPermission.reduce(
      (result: SidebarNavigationItem[], current) =>
        result.concat(
          current.children && current.children.length > 0
            ? current.children
            : current,
        ),
      [],
    )
  }, [listSidebarNavigationWithPermission])

  const currentMenuItem = useMemo(() => {
    const itemExist = sidebarNavFlat.find(
      ({ url }) => url === location.pathname,
    )

    if (itemExist) {
      return itemExist
    }

    const pathNames = location.pathname
      .split('/')
      .filter(item => !isEmpty(item))

    const commonItem = sidebarNavFlat.find(side => {
      const exist = pathNames.find(item => isEqual(side?.url, `/${item}`))
      return !!exist
    })

    if (commonItem) {
      return commonItem
    }
  }, [sidebarNavFlat, location.pathname])

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : []

  const openedSubmenu = listSidebarNavigationWithPermission.find(
    ({ children }) => children?.some(({ url }) => url === location.pathname),
  )

  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : []

  const renderLabel = ({
    key,
    nav,
    isSubMenu,
  }: {
    key: string
    isSubMenu?: boolean
    nav: SidebarNavigationItem
  }) => {
    const isSelected = key === defaultSelectedKeys?.[0]

    let target: string | undefined = undefined
    let rel: string | undefined = undefined

    // is link set target to _blank, rel to noopener noreferer
    if (nav.isLink && !!nav?.url) {
      target = '_blank'
      rel = 'noopener noreferer'
    }

    const stylesOverwrite = (() => {
      if (!isCollapsed) {
        return
      }
      return {
        height: '30px',
      }
    })()

    if (isCollapsed && isSubMenu) {
      return (
        <S.ItemMenuCustomWrapper
          align={'middle'}
          $isSelected={isSelected}
          style={stylesOverwrite}
        >
          <S.LeftPoint $isSelected={isSelected} />
          {isSelected ? nav?.iconFocus : nav?.icon}
        </S.ItemMenuCustomWrapper>
      )
    }

    if (isCollapsed && !isSubMenu) {
      return (
        <Link to={nav.url || ''} target={target} rel={rel}>
          <S.ItemMenuCustomWrapper
            align={'middle'}
            $isSelected={isSelected}
            style={stylesOverwrite}
          >
            <S.LeftPoint $isSelected={isSelected} />
            {isSelected ? nav?.iconFocus : nav?.icon}
          </S.ItemMenuCustomWrapper>
        </Link>
      )
    }

    return (
      <S.ItemMenuCustomWrapper
        align={'middle'}
        $isSelected={isSelected}
        style={stylesOverwrite}
      >
        <S.LeftPoint $isSelected={isSelected} />
        {isSelected ? nav?.iconFocus : nav?.icon}
        <S.LabelCustom $isSelected={isSelected}>
          {isSubMenu ? (
            t(nav.title)
          ) : (
            <Link to={nav.url || ''} target={target} rel={rel}>
              {t(nav.title)}
            </Link>
          )}
        </S.LabelCustom>
      </S.ItemMenuCustomWrapper>
    )
  }

  const renderLabelChild = useCallback(
    ({ key, nav }: { key: string; nav: SidebarNavigationItem }) => {
      const isSelected = key === defaultSelectedKeys?.[0]
      let target: string | undefined = undefined
      let rel: string | undefined = undefined

      // is link set target to _blank, rel to noopener noreferer
      if (nav.isLink && !!nav?.url) {
        target = '_blank'
        rel = 'noopener noreferer'
      }

      return (
        <S.ItemMenuCustomWrapper align={'middle'} $isSelected={isSelected}>
          <>
            <S.LeftPoint $isSelected={isSelected} />
            <S.LeftPointSubMenu $isSelected={isSelected} />
          </>
          <S.LabelCustom $isSelected={isSelected}>
            <Link to={nav.url || ''} target={target} rel={rel}>
              {t(nav.title)}
            </Link>
          </S.LabelCustom>
        </S.ItemMenuCustomWrapper>
      )
    },
    [defaultSelectedKeys],
  )

  const onClickMenuItem = () => {
    if (mobileOnly) {
      setCollapsed(true)
    }
  }

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={onClickMenuItem}
      items={listSidebarNavigationWithPermission.map(nav => {
        const isSubMenu = nav.children?.length
        return {
          key: nav.key,
          title: t(nav.title),
          label: renderLabel({ key: nav.key, isSubMenu: !!isSubMenu, nav }),
          style: {
            padding: 0,
            paddingLeft: 0,
            paddingInline: 0,
            backgroundColor: colors.white,
          },
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map(childNav => ({
              key: childNav.key,
              style: {
                padding: 0,
                paddingLeft: 0,
                paddingInline: 0,
                backgroundColor: colors.white,
                marginBlock: 0,
              },
              label: renderLabelChild({
                key: childNav.key,
                nav: childNav,
              }),
              title: t(childNav.title),
            })),
        } as ItemType<MenuItemType>
      })}
    />
  )
}

export default SliderMenu
