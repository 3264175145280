import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { useDeleteTum } from 'app/react-query/hook/tradeUnionMember'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  name?: string
  id?: string
}

export const DeleteModalLayout = (props: IProps) => {
  const { t } = useTranslation()

  const modalRef = useRef<IRefModal>({})

  const { mutateAsync: mutateAsyncDelete, isLoading } = useDeleteTum()

  const handleDelete = async () => {
    // validate id
    if (!props?.id) {
      return
    }

    try {
      await mutateAsyncDelete({ listId: [props?.id] })
      modalRef.current?.hide?.()
    } catch (error) {
      console.log('Error:', error)
    }
  }

  return (
    <BaseModalComponent
      titleModal={'Xoá đoàn viên'}
      titleOpenModal={t(R.strings.delete)}
      widthModal={400}
      ref={modalRef}
      handleSubmit={handleDelete}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseSpace>
          <BaseRow gutter={8} align={'middle'} justify={'center'}>
            <BaseCol>
              <BaseText fontWeight="medium" fontSize="xs">
                {'Bạn có thực sự muốn xoá đoàn viên?'}
              </BaseText>
            </BaseCol>
            <BaseCol>
              <BaseText
                children={`"${props?.name ?? ''}"`}
                colorText="statesRedColor"
                fontWeight="medium"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>
          <BaseRow align={'middle'} justify={'center'}>
            <BaseText fontWeight="medium" opacity="0.9">
              {
                'Đoàn viên này sẽ không thể khôi phục được và tài khoản đính kèm sẽ bị hạn chế chức năng khi không còn là đoàn viên!!'
              }
            </BaseText>
          </BaseRow>
        </BaseSpace>
      }
    />
  )
}
