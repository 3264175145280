/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnsType } from 'antd/es/table'
import {
  requestSyncTradeUnionMember,
  requestGetTumFomUeh,
} from 'app/api/tradeUnionMembers'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { _DEV_ } from 'app/constant'
import { TableTradeUnionMembersManagement } from 'app/model/trade-union-members-management'
import dayjs, { Dayjs } from 'dayjs'
import { isEmpty } from 'lodash'
import { Pagination, ResponseType, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IDataFormUpdate } from '../../TradeUnionManagementDetails/components/UpdateModal/type'
import { IFilterTradeUnionList } from '../../type'
import { notificationController } from 'app/controllers/notification-controller'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'

function chunkArray(
  array: TableTradeUnionMembersManagement[],
  size: number,
): TableTradeUnionMembersManagement[][] {
  const chunkedArray: any[][] = []
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size))
  }
  return chunkedArray
}

export const useHook = () => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IDataFormUpdate>()
  const refModal = useRef<IRefModal>({})

  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  const onSubmit = async () => {
    setTableData(prev => ({ ...prev, loading: true }))
    setIsLoadingConfirm(true)
    try {
      const list = chunkArray(tableData.data, 100)

      let listPromise: Promise<ResponseType<any>>[] = []

      list.forEach(item => {
        listPromise = listPromise.concat([
          requestSyncTradeUnionMember({
            data: item,
          }),
        ])
      })

      const res = await Promise.all(listPromise)

      if (res) {
        refModal?.current?.hide?.()
        notificationController.success({
          message: 'Đồng độ dữ liệu thành công!',
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setTableData(prev => ({ ...prev, loading: false }))
      setIsLoadingConfirm(false)
    }
  }

  const [tableData, setTableData] = useState<{
    data: TableTradeUnionMembersManagement[]
    pagination: Pagination
    loading: boolean
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  })
  const [filter, setFilter] = useState<IFilterTradeUnionList>({})

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setTableData(prev => ({ ...prev, loading: true }))
    const res: ResponseType<ITradeUnionMember[]> = await requestGetTumFomUeh()

    const data = res?.data

    const listFiler = data?.filter(item => {
      if (isEmpty(filter?.search) && !filter?.gender) return true

      if (!isEmpty(filter?.search)) {
        if (
          item?.fullName
            ?.toLowerCase()
            .includes(filter?.search?.toLowerCase() ?? '') ||
          item?.email
            ?.toLowerCase()
            .includes(filter?.search?.toLowerCase() ?? '') ||
          item?.dienThoaiDiDong
            ?.toString()
            ?.toLowerCase()
            .includes(filter?.search?.toLowerCase() ?? '') ||
          item?.cmnd
            ?.toLowerCase()
            .includes(filter?.search?.toLowerCase() ?? '')
        ) {
          return true
        }
      }

      return false
    })

    setTableData({
      data: listFiler,
      loading: false,
      pagination: initialPagination,
    })
  }
  const onChangeFilter = (newFilter: IFilterTradeUnionList) => {
    setFilter(newFilter)
  }

  useEffect(() => {
    fetch()
  }, [filter])

  const onChangeDateJoinByIndex = (day: Dayjs | null, index: number) => {
    setTableData(prev => {
      const newData = prev.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            ngayVaoCongDoan: day?.toISOString(),
          }
        }
        return item
      })
      return {
        ...prev,
        data: newData,
      }
    })
  }

  const onChangeStatusByIndex = (value: boolean, index: number) => {
    setTableData(prev => {
      const newData = prev.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            is_active: value,
          }
        }
        return item
      })
      return {
        ...prev,
        data: newData,
      }
    })
  }

  const onChangeJobTitleByIndex = (value: string, index: number) => {
    setTableData(prev => {
      const newData = prev.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            chucVucongdoan: value,
          }
        }
        return item
      })
      return {
        ...prev,
        data: newData,
      }
    })
  }

  const columns: ColumnsType<TableTradeUnionMembersManagement> = useMemo(() => {
    return [
      convertColumnTable({
        title: t(R.strings.full_name),
        className: 'big-column',
        fixed: 'left',
        key: 'fullName',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.fullName}
              fontWeight="semibold"
              fontSize="xs"
              colorText="collapseBackgroundColor"
            />
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.CCCD),
        key: 'cmnd',
        className: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.cmnd}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable({
        title: 'Tên đơn vị',
        key: 'tenDonVi',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            <BaseText
              children={!isEmpty(record?.tenDonVi) ? record?.tenDonVi : '-'}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable({
        title: 'Mã quản lý',
        key: 'mql',
        className: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.mql}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.email),
        className: 'big-column',
        key: 'email',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.email}
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),

      convertColumnTable({
        title: t(R.strings.date_of_union_entry),
        className: 'very-big-column',
        key: 'ngayVaoCongDoan',
        render: (_, record, index) => {
          return (
            <BaseDatePickerWrapper
              value={
                record?.ngayVaoCongDoan
                  ? dayjs(record?.ngayVaoCongDoan)
                  : undefined
              }
              onChange={value => onChangeDateJoinByIndex(value, index)}
              picker="year"
            />
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.union_card_number),
        className: 'big-column',
        key: 'soTheDoanVien',
        render: (_, record) => {
          return (
            <BaseInput
              placeholder={t(R.strings.enter_union_card_number)}
              value={record?.soTheDoanVien}
              onChange={e => {
                setTableData(prev => {
                  const newData = prev.data.map(item => {
                    if (item === record) {
                      return {
                        ...item,
                        soTheDoanVien: e.target.value,
                      }
                    }
                    return item
                  })
                  return {
                    ...prev,
                    data: newData,
                  }
                })
              }}
            />
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.status_union_activities),
        className: 'big-column',
        key: 'tinhTrangLamViec',
        render: (_, record, index) => {
          return (
            <BaseSelect
              placeholder={'Chọn tình trạng'}
              width={260}
              onChange={value => onChangeStatusByIndex(value as boolean, index)}
              allowClear
              value={record?.is_active}
              options={[
                {
                  label: 'Hoạt động',
                  value: true,
                },
                {
                  label: 'Không hoạt động',
                  value: false,
                },
              ]}
            />
          )
        },
      }),
      convertColumnTable({
        title: t(R.strings.union_positions),
        className: 'very-big-column',
        key: 'chucVucongdoan',
        render: (_, record, index) => {
          return (
            <BaseInput
              placeholder={t(R.strings.enter_union_positions)}
              value={record?.chucVucongdoan}
              onChange={e => onChangeJobTitleByIndex(e.target.value, index)}
            />
          )
        },
      }),
    ]
  }, [t, tableData?.data])

  return {
    columns,
    tableData,
    onChangeFilter,
    filter,
    form,
    onSubmit,
    refModal,
    isLoadingConfirm,
  }
}
const BaseDatePickerWrapper = styled(BaseDatePicker)`
  width: 100%;
`
