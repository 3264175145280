import { useCreateEventContext } from '../../context'

export const useHook = () => {
  const { handleSubmit, form, eventType, onChangeEventType } =
    useCreateEventContext()

  return {
    form,
    eventType,
    onChangeEventType,
    handleSubmit,
  }
}
