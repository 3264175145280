/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestCreateTradeUnionMember,
  requestDeleteTradeUnionMember,
  requestGetTradeUnionMemberList,
  requestUpdateTradeUnionMember, // Đảm bảo bạn import đúng hàm API
} from 'app/api/tradeUnionMembers'
import {
  ITradeUnionMember,
  IParamsGetTradeUnionMember,
  IResponseTradeUnionMemberList,
  IPayloadCreateTradeUnionMember,
} from 'app/api/tradeUnionMembers/model'
import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { TradeUnionMemberKeys } from '../query-key/tradeUnionMember'
import { AccountKeys } from '../query-key/account'

export function useGetTradeUnionMember(
  params: IParamsGetTradeUnionMember,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<ITradeUnionMember[]>, undefined>(
    TradeUnionMemberKeys.listTradeUnionMember(params),
    async () => {
      try {
        const res: IResponseTradeUnionMemberList =
          await requestGetTradeUnionMemberList(params)
        const data: ITradeUnionMember[] = res?.data?.rows || []
        return {
          data,
          total: res?.data?.count ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

// create trade union member
export const useCreateTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateTradeUnionMember, unknown>(
    async (body: IPayloadCreateTradeUnionMember) => {
      return await requestCreateTradeUnionMember(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: TradeUnionMemberKeys.allTradeUnionMember,
        })

        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}

export const useUpdateTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, ITradeUnionMember, unknown>(
    async (body?: ITradeUnionMember) => {
      return await requestUpdateTradeUnionMember(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: TradeUnionMemberKeys.allTradeUnionMember,
        })
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}

// delete trade union member
export const useDeleteTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, { listId: string[] }, unknown>(
    async (body: { listId: string[] }) => {
      await requestDeleteTradeUnionMember(body?.listId)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: TradeUnionMemberKeys.allTradeUnionMember,
        })
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}
