import { IActionEvent } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import {
  useGetActionNotJoinByMember,
  useRegisterActionByMember,
} from 'app/react-query/hook/event'
import { useAppSelector } from 'app/redux/hooks'
import { useRef } from 'react'

export const ModalRegisterAction = ({ action }: { action?: IActionEvent }) => {
  const refModal = useRef<IRefModal>({})

  const user = useAppSelector(state => state?.user)?.profile
  const { refetch } = useGetActionNotJoinByMember()

  const { mutateAsync: mutateAsyncCreate, isLoading } =
    useRegisterActionByMember()

  const handleSubmit = () => {
    if (user?.member?.id && action?.id) {
      mutateAsyncCreate({
        member_id: user?.member?.id,
        activity_id: action?.id,
      })
      refetch?.()
      refModal.current?.hide?.()
    }
  }

  return (
    <BaseModalComponent
      ref={refModal}
      titleModal="Xác nhận đăng kí tham gia hoạt động"
      buttonOpenModal={
        <BaseButton
          type="primary"
          children="Đăng kí"
          onClick={() => refModal.current?.open?.()}
        />
      }
      handleSubmit={handleSubmit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseSpace size={8}>
          <BaseText
            children={`Bạn có thật sự muốn đăng kí tham gia hoạt động "${action?.name}"?`}
            fontWeight="medium"
            fontSize="xs"
          />
          <BaseText
            children={`Lưu ý: Sau khi đăng kí, cần thời gian để phê duyệt đăng kí của bạn.`}
            colorText="statesOrangeColor"
            opacity="0.8"
          />
        </BaseSpace>
      }
    />
  )
}
