import { EditOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { StatusEnum } from './type'

export const UpdateModal = () => {
  const { t } = useTranslation()
  const { form, rules, onSubmit, refModal } = useHook()

  return (
    <>
      <BaseModalComponent
        ref={refModal}
        titleModal={R.strings.update}
        handleSubmit={form.submit}
        iconOpenModal={<EditOutlined rev={undefined} />}
        renderContent={
          <BaseForm form={form} onFinish={onSubmit}>
            <BaseSpace>
              <BaseRow gutter={[16, 16]} align={'top'}>
                <BaseCol xl={12}>
                  <BaseForm.Item
                    label={t(R.strings.date_of_union_entry)}
                    required
                    name={'startDate'}
                    rules={rules.startDate}
                  >
                    <BaseDatePickerWrapper />
                  </BaseForm.Item>
                </BaseCol>
                <BaseCol xl={12}>
                  <BaseForm.Item
                    label={t(R.strings.union_card_number)}
                    required
                    rules={rules.unionCardNumber}
                    name={'unionCardNumber'}
                  >
                    <BaseInput
                      placeholder={t(R.strings.enter_union_card_number)}
                    />
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>
              <BaseRow gutter={[16, 16]} align={'top'}>
                <BaseCol xl={12}>
                  <BaseForm.Item
                    label={t(R.strings.status_union_activities)}
                    required
                    name={'statusUnionActivities'}
                    rules={rules.statusUnionActivities}
                  >
                    <BaseSelect placeholder={'Chọn tình trạng'}>
                      <Option
                        value={StatusEnum.Approval}
                        children={t(R.strings.approved)}
                      />
                      <Option
                        value={StatusEnum.Pending}
                        children={t(R.strings.pending_execution)}
                      />
                    </BaseSelect>
                  </BaseForm.Item>
                </BaseCol>
                <BaseCol xl={12}>
                  <BaseForm.Item
                    label={t(R.strings.union_positions)}
                    required
                    rules={rules.unionPositions}
                    name={'unionPositions'}
                  >
                    <BaseInput
                      placeholder={t(R.strings.enter_union_positions)}
                    />
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>
            </BaseSpace>
          </BaseForm>
        }
      />
    </>
  )
}

const BaseDatePickerWrapper = styled(BaseDatePicker)`
  width: 100%;
`
