import { TypeEventEnum } from 'app/api/event/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'

export const InfoEvent = () => {
  const { t } = useTranslation()
  const { form, handleSubmit, eventType, onChangeEventType } = useHook()

  return (
    <BaseSpace>
      <BaseForm form={form} onFinish={handleSubmit}>
        <RootWrapper gutter={[16, 8]} align={'top'}>
          <BaseCol xl={24}>
            <BaseSpace size={0}>
              <BaseForm.Item
                name={'name'}
                required
                label={t(R.strings.event_name)}
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: t(R.strings.event_name),
                    }),
                  },
                ]}
              >
                <BaseInput placeholder={t(R.strings.enter_event_name)} />
              </BaseForm.Item>

              <BaseForm.Item
                name={'type'}
                required
                label="Loại sự kiện (nhóm)"
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: 'Loại sự kiện (nhóm)',
                    }),
                  },
                ]}
              >
                <BaseSelect
                  placeholder={'Chọn loại sự kiện (nhóm)'}
                  options={[
                    {
                      label: 'Nhóm thông tin',
                      value: TypeEventEnum.NEWS,
                    },
                    {
                      label: 'Nhóm Tập huấn - Hội thảo - Đào tạo',
                      value: TypeEventEnum.TRAINING,
                    },
                    {
                      label: 'Nhóm Hội thi',
                      value: TypeEventEnum.ACTIVITY,
                    },
                    {
                      label: 'Nhóm Hoạt động chăm lo',
                      value: TypeEventEnum.CARE,
                    },
                  ]}
                  onChange={value =>
                    onChangeEventType?.(value as TypeEventEnum)
                  }
                />
              </BaseForm.Item>

              {isEqual(eventType, TypeEventEnum.NEWS) || isEqual(eventType, TypeEventEnum.CARE)  ? null : (
                <BaseRow align={'top'} gutter={[16, 8]}>
                  <BaseCol xl={12}>
                    <BaseForm.Item
                      name={'startDateRegister'}
                      required
                      label={t(R.strings.start_date_register)}
                      dependencies={['endDateRegister']}
                      rules={[
                        {
                          required: true,
                          message: t(R.strings.require_field, {
                            field: t(R.strings.start_date_register),
                          }),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const day = value as Dayjs

                            if (!day || !getFieldValue('endDateRegister')) {
                              return Promise.resolve()
                            }
                            if (
                              day.isSame(getFieldValue('endDateRegister')) ||
                              day.isBefore(getFieldValue('endDateRegister'))
                            ) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              t(R.strings.start_date_must_be_before_end_date),
                            )
                          },
                        }),
                      ]}
                    >
                      <BaseDatePickerStyled
                        format={'DD-MM-YYYY HH:mm'}
                        placeholder={t(R.strings.start_date_register)}
                        showTime
                      />
                    </BaseForm.Item>
                  </BaseCol>

                  <BaseCol xl={12}>
                    <BaseForm.Item
                      name={'endDateRegister'}
                      required
                      label={t(R.strings.end_date_register)}
                      dependencies={['startDateRegister', 'startDate']}
                      rules={[
                        {
                          required: true,
                          message: t(R.strings.require_field, {
                            field: t(R.strings.end_date_register),
                          }),
                        },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const day = value as Dayjs

                            if (!day || !getFieldValue('startDateRegister')) {
                              return Promise.resolve()
                            }
                            if (
                              day.isSame(getFieldValue('startDateRegister')) ||
                              day.isAfter(getFieldValue('startDateRegister'))
                            ) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              t(R.strings.end_date_must_be_after_start_date),
                            )
                          },
                        }),

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const day = value as Dayjs

                            if (!day || !getFieldValue('startDate')) {
                              return Promise.resolve()
                            }
                            if (day.isBefore(getFieldValue('startDate'))) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              t(
                                R.strings
                                  .end_date_register_must_be_before_start_date,
                              ),
                            )
                          },
                        }),
                      ]}
                    >
                      <BaseDatePickerStyled
                        format={'DD-MM-YYYY HH:mm'}
                        placeholder={t(R.strings.end_date_register)}
                        showTime
                      />
                    </BaseForm.Item>
                  </BaseCol>
                </BaseRow>
              )}

              {isEqual(eventType, TypeEventEnum.NEWS) ? null : (
                <BaseRow align={'top'} gutter={[16, 8]}>
                  <BaseCol xl={12}>
                    <BaseForm.Item
                      name={'startDate'}
                      required
                      label={t(R.strings.start_date_event)}
                      dependencies={['endDate', 'endDateRegister']}
                      rules={[
                        {
                          required: true,
                          message: t(R.strings.require_field, {
                            field: t(R.strings.start_date_event),
                          }),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const day = value as Dayjs

                            if (!day || !getFieldValue('endDate')) {
                              return Promise.resolve()
                            }
                            if (
                              day.isSame(getFieldValue('endDate')) ||
                              day.isBefore(getFieldValue('endDate'))
                            ) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              t(R.strings.start_date_must_be_before_end_date),
                            )
                          },
                        }),

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const day = value as Dayjs

                            if (!day || !getFieldValue('endDateRegister')) {
                              return Promise.resolve()
                            }
                            if (day.isAfter(getFieldValue('endDateRegister'))) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              t(
                                R.strings
                                  .start_date_event_must_be_after_end_date_register,
                              ),
                            )
                          },
                        }),
                      ]}
                    >
                      <BaseDatePickerStyled
                        format={'DD-MM-YYYY HH:mm'}
                        placeholder={t(R.strings.start_date_event)}
                        showTime
                      />
                    </BaseForm.Item>
                  </BaseCol>

                  <BaseCol xl={12}>
                    <BaseForm.Item
                      name={'endDate'}
                      required
                      label={t(R.strings.end_date_event)}
                      dependencies={['startDate']}
                      rules={[
                        {
                          required: true,
                          message: t(R.strings.require_field, {
                            field: t(R.strings.end_date_event),
                          }),
                        },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const day = value as Dayjs

                            if (!day || !getFieldValue('startDate')) {
                              return Promise.resolve()
                            }
                            if (
                              day.isSame(getFieldValue('startDate')) ||
                              day.isAfter(getFieldValue('startDate'))
                            ) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              t(R.strings.end_date_must_be_after_start_date),
                            )
                          },
                        }),
                      ]}
                    >
                      <BaseDatePickerStyled
                        format={'DD-MM-YYYY HH:mm'}
                        placeholder={t(R.strings.end_date_event)}
                        showTime
                      />
                    </BaseForm.Item>
                  </BaseCol>
                </BaseRow>
              )}

              {isEqual(eventType, TypeEventEnum?.NEWS) ? (
                <BaseForm.Item
                  required
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: 'Link tin tức',
                      }),
                    },
                  ]}
                  name={'link'}
                  label={'Link tin tức'}
                >
                  <BaseInput placeholder="Nhập link tin tức" />
                </BaseForm.Item>
              ) : null}

              <BaseForm.Item
                name={'description'}
                label={t(R.strings.description)}
              >
                <BaseInput.TextArea placeholder={t(R.strings.description)} />
              </BaseForm.Item>
            </BaseSpace>
          </BaseCol>
        </RootWrapper>
      </BaseForm>
    </BaseSpace>
  )
}

const RootWrapper = styled(BaseRow)`
  max-height: 500px;
  overflow-y: auto;
`

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
