import { Rule } from 'antd/es/form'

export enum StatusEnum {
  Pending = 1,
  Approval = 2,
}

export interface IDataFormUpdate {
  startDate?: string
  status?: StatusEnum
  unionCardNumber?: string
  unionPositions?: string
  statusUnionActivities?: string
}

export interface IRuleFormUpdate {
  startDate: Rule[]
  unionCardNumber: Rule[]
  status?: Rule[]
  unionPositions: Rule[]
  statusUnionActivities: Rule[]
}
